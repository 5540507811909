import { Box, Text, Button } from "grommet";
import Image from "next/legacy/image";
import styled from "styled-components";
import useIsLarge from "@cybertools/ui/es/hooks/useIsLarge";
import Link from "next/link";
import { useCoin } from "@organisms/coin/provider";
import VoteIcon from "public/assets/coinlist/vote.svg";
import VotedIcon from "public/assets/coinlist/voted.svg";

const StyledVoteButton = styled(Button)`
  ${({ small }) => (small ? `width: 91px; padding: 4px 8px;` : `width: 91px;`)}
  border-width: 1px;
`;

function VoteButton({ slug, votes, voted, ...rest }) {
  return (
    <Link href={`/coin/${slug}`} passHref legacyBehavior>
      <StyledVoteButton
        title="Vote"
        onClick={() => {}}
        primary={voted}
        {...rest}
      >
        {(props) => (
          <Box
            direction="column"
            align="center"
            gap="xxsmall"
            justify="between"
            {...props}
          >
            <Image
              color="brand"
              alt="Vote Icon"
              src={voted ? VotedIcon : VoteIcon}
            />
            <Text size="small" color={voted ? "light-1" : "brand"}>
              {votes}
            </Text>
          </Box>
        )}
      </StyledVoteButton>
    </Link>
  );
}

export default function Vote({ slug }) {
  const { votes, voted } = useCoin(slug);
  const large = useIsLarge();

  if (large)
    return <VoteButton slug={slug} votes={votes} voted={voted}></VoteButton>;

  return (
    <Box align="center">
      <VoteButton slug={slug} votes={votes} voted={voted}></VoteButton>
    </Box>
  );
}
